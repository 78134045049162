
  @mixin fontSize($size: 1px) {
    @for $i from 5 through 100 {
      .fs-#{$i} {
        font-size: $i+px  !important;
      }
    }
  }
  @mixin lineHeight($size: 1px) {
    @for $i from 5 through 100 {
      .lh-#{$i} {
        line-height: $i+px  !important;
      }
    }
  }

