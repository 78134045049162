//
//
//@font-face {
//    font-family: 'Poppins';
//    src: url('Poppins-Bold.eot');
//    src: url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
//        url('Poppins-Bold.woff2') format('woff2'),
//        url('Poppins-Bold.woff') format('woff'),
//        url('Poppins-Bold.ttf') format('truetype');
//    font-weight: bold;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Poppins';
//    src: url('Poppins-Medium.eot');
//    src: url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
//        url('Poppins-Medium.woff2') format('woff2'),
//        url('Poppins-Medium.woff') format('woff'),
//        url('Poppins-Medium.ttf') format('truetype');
//    font-weight: 500;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Poppins';
//    src: url('Poppins-Regular.eot');
//    src: url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
//        url('Poppins-Regular.woff2') format('woff2'),
//        url('Poppins-Regular.woff') format('woff'),
//        url('Poppins-Regular.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//    font-display: swap;
//}
//
//@font-face {
//    font-family: 'Poppins';
//    src: url('Poppins-SemiBold.eot');
//    src: url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
//        url('Poppins-SemiBold.woff2') format('woff2'),
//        url('Poppins-SemiBold.woff') format('woff'),
//        url('Poppins-SemiBold.ttf') format('truetype');
//    font-weight: 600;
//    font-style: normal;
//    font-display: swap;
//}

@mixin fontFace($fontFamily, $fontWeight, $fontStyle, $fontFileName) {
    @font-face {
        font-family: $fontFamily;
        src: url('#{$fontFileName}.eot');
        src: url('#{$fontFileName}.eot?#iefix') format('embedded-opentype'),
        url('#{$fontFileName}.woff2') format('woff2'),
        url('#{$fontFileName}.woff') format('woff'),
        url('#{$fontFileName}.ttf') format('truetype');
        font-weight: $fontWeight;
        font-style: $fontStyle;
        font-display: swap;
    }
}

@include fontFace('Poppins', bold, normal, 'Poppins-Bold');
@include fontFace('Poppins', 500, normal, 'Poppins-Medium');
@include fontFace('Poppins', normal, normal, 'Poppins-Regular');
@include fontFace('Poppins', 600, normal, 'Poppins-SemiBold');