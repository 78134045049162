@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fstfim');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fstfim#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fstfim') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fstfim') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fstfim##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter-filled {
  &:before {
    content: $icon-filter-filled; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-burger {
  &:before {
    content: $icon-burger; 
  }
}
.icon-swimming {
  &:before {
    content: $icon-swimming; 
  }
}
.icon-electrical {
  &:before {
    content: $icon-electrical; 
  }
}
.icon-air {
  &:before {
    content: $icon-air; 
  }
}
.icon-plumb {
  &:before {
    content: $icon-plumb; 
  }
}
.icon-irrigation {
  &:before {
    content: $icon-irrigation; 
  }
}
.icon-sencess {
  &:before {
    content: $icon-sencess; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-individual {
  &:before {
    content: $icon-individual; 
  }
}
.icon-settign-calendar {
  &:before {
    content: $icon-settign-calendar; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-check-list {
  &:before {
    content: $icon-check-list; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-company {
  &:before {
    content: $icon-company; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-gear {
  &:before {
    content: $icon-gear; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-pen {
  &:before {
    content: $icon-pen; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}

