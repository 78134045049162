@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import './../src/assets/font/font';
@import './../src/assets/icon/style';
@import './../src/assets/icon/variables';
@import './../src/assets/scss/mixins/mixin';
@import "react-datepicker/dist/react-datepicker.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
$primary:#63b4f5;
@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
		overflow-x: hidden;
	}
}
/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #00007d;
	border-radius: 10px;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}
.box-shadow{
	box-shadow: 11px 7px 16px 0 rgba(65, 163, 244, 0.07);
}
.main{
	background: #DDEEFD;
}
.text-red{
	color: #fe0000;
}

.font-bold {
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
}

.font-medium {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.font-regular {
	font-family: 'Poppins', sans-serif;
	font-weight: normal;
}

.font-semiBold {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}
.btn{
	padding: 10px 20px;
	border-radius: 0 27px;
	min-width: 150px;
	&.btnGroup{
		height: 100px;
		width: 260px;
		&.active{
			box-shadow: 7px 17px 54.4px 0 rgba(17, 106, 204, 0.12);
		}
	}
}
.lg-radius{
	border-radius: 0 41px;
}
.custom-select{
	[class$="control"]{
		border-radius: 50px;
		border-color: rgba(65, 163, 244, 0.16) !important;
		box-shadow: none !important;
	}
	[class$="singleValue"]{
		font-size: 14px;
	}
	[class$="placeholder"]{
		font-weight: normal;
		font-size: 12px;
	}
	[class$="indicatorSeparator"]{
		display: none;
	}
	&.disabled{
		[class$="control"]{
			border: none !important;
			background: #DDEEFD !important;
		}
	}
}
.dark{
	.custom-select{
		[class$="control"]{
			background: transparent !important;
		}
		[class$="placeholder"]{
			font-weight: normal;
			font-size: 12px;
		}
		[class$="indicatorSeparator"]{
			display: none;
		}
		&.disabled{
			[class$="control"]{
				border: none !important;
				background: #DDEEFD !important;
			}
		}
	}
}
input{
	border-color: rgba(65, 163, 244, 0.16);
	font-size: 14px;
	&::placeholder{
		font-weight: normal;
		font-size: 12px;
	}
}
.custom-table{
	border-collapse:separate;
	border-spacing:0 15px;
	thead{
		tr{
			th{
				border: none;
				&:first-child{
					border-top-left-radius: 50px;
					border-bottom-left-radius: 50px;
					padding-left: 20px;
				}
				&:last-child{
					border-top-right-radius: 50px;
					border-bottom-right-radius: 50px;
				}
			}
			td{
				border: none;
			}
		}
	}
	tbody{

		tr{


			td{
				border: none;
				box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.06);
				background: #FFF;
				&:first-child{
					border-top-left-radius: 50px;
					border-bottom-left-radius: 50px;
				}
				&:last-child{
					border-top-right-radius: 50px;
					border-bottom-right-radius: 50px;
				}
			}
			&:nth-child(even) {
				td{
					background: rgba(99, 180, 245, 0.11);
				}
			}
		}
	}
}
.home-btn{
	border-radius: 0 27px;
	background: #B7DFFD;
	box-shadow: 10px 7px 16px 0 rgba(33, 148, 245, 0.30);
	em{
		font-size: 80px;
	}
	span{
		font-size: 20px;
	}
	@media screen and (max-width: 768px){
		em{
			font-size: 50px;
		}
		span{
			font-size: 17px;
		}
	}
}
.action-btn{
	width: 28px;
	height: 28px;
	border-radius: 6px;
}
/*************sidebar************/

.sidebarControlBtn{
	width: 30px;
	height: 30px;
	box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.10);
}

.sidebar{
	&.closed-sidebar{
		@media screen and (min-width: 768px){
			width: 120px;
			.sidebar-link{
				p {
					display: none;
				}
			}
		}
	}
	.sidebar-content{
		height: calc(100vh - 150px);
		overflow-y: auto;
		padding-right: 15px;
	}
	.link{
		&.parent{
			.sidebar-link{
				position: relative;
				&:after{
					content: $icon-chevron-down;
					font-family: 'alfoadia', sans-serif;
					position: absolute;
					right: 8px;
					top: 4px;
					color: #0D45A2;
					font-size: 12px;
				}
				&:hover{
					&:after{
						color: white;
					}
				}
			}
			&.active {
				.sidebar-link{
					&:after{
					 color: white;
					}
				}
			}
		}
	}
	.sidebarLink{
		&:hover{
			border-radius: 0 21px 0 0 !important;
			span, p{
				color: white;
			}
		}
	}
}
/*************sidebar************/
.dashed-border{
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='21' ry='21' stroke='%2341A3F429' stroke-width='3' stroke-dasharray='6%2c 15' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 21px;
}
.dashed-border-black{
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23181C30FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='31' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 5px;
}

.chakra-portal{
	.chakra-modal__overlay {
	 z-index: 30;
	}
	.chakra-modal__content-container{
		bottom: 0;
		z-index: 31;
      .modal-body{
        position: relative;
		  background-repeat: no-repeat;
		  background-position: center bottom;
		  background-size: contain;
		  max-height: 90vh;

      }
	.close-modal{
		position: absolute;
		right: 45px;
		z-index: 9999;
		border-radius: 50px;
		top: -17px;
		filter: drop-shadow(3px 2px 5.5px rgba(186, 0, 0, 0.11));
	}
	}
}

/*************react-datepicker************/
.react-datepicker-wrapper{
	width: 100%;
	.react-datepicker__input-container{
		width: 100%;
		padding-top: 1px;
		&:after{
			content: $icon-calendar;
			font-family: 'alfoadia', sans-serif;
			position: absolute;
			right: 17px;
			font-size: 18px;
			top: 5px;
			color: rgba(135, 135, 135, 0.4);
			cursor: pointer;
			pointer-events: none;
		}
		input{
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			border: 1px solid rgba(65, 163, 244, 0.16);
			height: 37px;
			width: 100%;
			border-radius: 50px;
			&:focus-visible{
				outline: none !important;
			}

		}
	}

}
.react-datepicker__header {
	background-color: $primary !important;
	border-bottom: 1px solid $primary !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
	color: white !important;
}
.react-datepicker__day-name {
	color: white !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
	background-color: $primary !important;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
	border-color:  white !important;;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
	border-bottom-color:  $primary !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
	border-bottom-color: $primary !important;
}

/************react-timepicker*************/
.time-picker{
	.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after ,
	.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
		left: -30px !important;
	}
	.react-datepicker-wrapper{width: 100%;
		.react-datepicker__input-container{
			&:after{
				content: $icon-clock;
				font-family: 'alfoadia', sans-serif;
			}
		}
	}
}
/************react-datepicker*************/

.dashCard{
	border-radius: 22px 0;
	padding: 15px 0 5px 15px;
	background-image: url("./assets/img/layout/sences.png");
	background-position: right bottom;
	position: relative;
	&:after{
		content: url("./assets/img/layout/sences.png");
		position: absolute;
		right: 0;
		bottom: 0;
		//background-image: ;
		background-position: right bottom;

	}
	&.purple{
		background: linear-gradient(90deg, #8B68A6 1.85%, #E6D1F4 99.87%);
		box-shadow: 10px 7px 16px 0 rgba(172, 142, 195, 0.30);
	}
	&.blue{
		background: linear-gradient(270deg, rgba(65, 163, 244, 0.83) 0%, rgba(13, 69, 162, 0.83) 100%);
		box-shadow: 10px 7px 16px 0 rgba(67, 124, 198, 0.50);
	}
}
.card-style{
	border-radius: 26px;
	border: 1px solid #DDEEFD;
	background: #FFF;
	box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.11);
}

.simple-addBtn{
	min-width: 35px;
	width: 35px;
	height: 35px;
	border-radius: 6px;
	padding: 0 !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.selectMember{
	.CustomSelect{
		min-width: 250px;
	}
}

.check-card{
	border-radius: 26px;
	border: 1px solid #DDEEFD;
	box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.11);
	height: 100%;
	&.blue{
		em{
			color: #DDEEFD !important;
			opacity: 0.7 !important;
		}
	}
	&.light{
		em{
			color: #B1BEE9 !important;
		}
	}
	&.purple{
		em{
			color: #8B68A6 !important;
			opacity: 0.15 !important;
		}
	}
	&.indigo{
		em{
			color: #8095CF !important;
			opacity: 0.15 !important;
		}
	}
	&.pink{
		em{
			color: #E6D1F4 !important;
			opacity: 0.4 !important;
		}
	}
	&.cyan{
		em{
			color: #93C4EA !important;
			opacity: 0.3 !important;
		}
	}
}
.setting-table{
	tr td:last-child{
		width: 150px;
	}
}

.work-table{
	thead{
		tr{
			th:nth-last-child(2){
				max-width: 125px;
			}
		}
	}
}

.simple-card{
	border-radius: 26px;
	border: 1px solid #DDEEFD;
	background: #FFF;
	box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.11);
	position: relative;
	overflow-x: auto;
	&:after{
		content: $icon-sencess;
		font-family: 'alfoadia', sans-serif;
		position: absolute;
		right: 0px;
		font-size: 80px;
		bottom: 3px;
		color: rgba(177, 190, 233, 0.14);
		cursor: pointer;
		pointer-events: none;
	}
}
.border-top-blue{
	border-top: 1px solid #DDEEFD;
}
.shadow-card{
	box-shadow: 10px 7px 16px 0 rgba(0, 0, 0, 0.06);

}
.shadow-card-2{
	box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.11);
}
.grad-pink{
	background: linear-gradient(90deg,  rgba(197, 138, 223, 0.00) 0, #C58ADF 100%);
}
.checkListCard{
	.simple-card{
		em{
			color: #0D45A2;
		}
		&:hover, &.active{
			cursor: pointer;
			background: #0D45A2;
			color: white;
			em{
				color: white;
			}
		}
	}
}

.custom-stepper{
	.stepper{
		position: relative;

		&:not(:last-child){
			&:after{
				content: '';
				height: 4px;
				width: 80px;
				border-radius: 5px;
				background: #EBEEF2;
				position: absolute;
				left: 60px;
				top: 19px;
			}
		}
		&.active{
			&:not(:last-child){
				&:after{
					background: #63B4F5;
				}
			}
		}
		@media screen and (min-width: 768px){
			width: 200px;
			&:not(:last-child){
				&:after{
					width: 140px;
				}
			}
		}
	}
	.step{
		border-radius: 6px;
		background: rgba(138, 146, 166, 0.30);
		outline: 8px solid rgba(235, 238, 242, 0.30);
		width: 43px;
		height: 43px;
		flex-shrink: 0;
		color: rgb(255 255 255);
		&.activeStep{
			background: #63B4F5;
			box-shadow: 2px 4px 24px 0 #63B4F5;
			outline: 8px solid rgba(99, 180, 245, 0.37);
		}
	}
	&.three-step{
		.stepper{
			&:not(:last-child){
				&:after{
					@media screen and (max-width: 768px){
						width: 35px;
					}
				}
			}
		}
	}
	}

.dark{
	.custom-table{
		tbody{
			tr{
				td{
					box-shadow: none !important;
					background: #141d40;
				}
				&:nth-child(even) {
					td{
						background: rgba(99, 180, 245, 0.11);
					}
				}
			}
		}
	}
	.react-datepicker-wrapper{
		.react-datepicker__input-container{
			input{
				background: transparent;
				color: white;

			}
		}

	}
}

.custom-radius{
	.custom-select{
		[class$="control"]{
			border-radius: 10px !important;
		}
	}
	.custom-select{
		[class$="control"]{
			border-radius: 10px !important;
		}
	}
	input{
		border-radius: 10px !important;
	}
}

.logo{
	width:320px;
	height:90px;
}
.vision{
	width:100px ;
	height:66px;
}
@media  screen and (max-width: 768px){

	.logo{
		width:180px !important;
		height:60px !important;
	}
	.vision{
		width:85px !important;
		height:60px !important;
	}
}


@media screen and (max-width: 768px){
	.modal-body{
		overflow-y: auto;
		padding-bottom: 30px;
	}
	.logo{
		width:200px;
		height:70px;
	}
	.vision{
		width:90px;
		height:60px;
	}
}

.card-table{
	padding: 16px 10px;
	border-radius: 26px;
	border: 1px solid #DDEEFD;
	background: #FFF;
	box-shadow: 10px 7px 16px 0 rgba(99, 180, 245, 0.11);
}

.overlay{
	position: absolute;
	background: #3b71bebd;
	width: 100vw;
	height: 100%;
    left: 0;
	bottom: 0;
	top: 0;
	right: 0;
	z-index: 30;

}