$icomoon-font-family: "alfoadia" !default;
$icomoon-font-path: "fonts" !default;

$icon-filter-filled: "\e923";
$icon-filter: "\e924";
$icon-burger: "\e922";
$icon-swimming: "\e91d";
$icon-electrical: "\e91e";
$icon-air: "\e91f";
$icon-plumb: "\e920";
$icon-irrigation: "\e921";
$icon-sencess: "\e91c";
$icon-user: "\e91a";
$icon-lock: "\e900";
$icon-unlock: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e91b";
$icon-mail: "\e903";
$icon-individual: "\e904";
$icon-settign-calendar: "\e905";
$icon-trash: "\e906";
$icon-upload: "\e907";
$icon-users: "\e908";
$icon-calendar: "\e909";
$icon-check: "\e90a";
$icon-check-list: "\e90b";
$icon-chevron-down: "\e90c";
$icon-chevron-right: "\e90d";
$icon-chevron-left: "\e919";
$icon-clock: "\e90e";
$icon-close: "\e90f";
$icon-company: "\e910";
$icon-dashboard: "\e911";
$icon-eye: "\e912";
$icon-folder: "\e913";
$icon-gear: "\e914";
$icon-list: "\e915";
$icon-notification: "\e916";
$icon-pen: "\e917";
$icon-plus: "\e918";

